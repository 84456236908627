import { useState, useEffect, useContext } from "react";
import DateObject from "react-date-object";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";

import Navbar from "../../components/navbar";
import LinePeopleItem from "../../components/linePeopleItem";
import LineChatItem from "../../components/lineChatItem";
import storageBox from "../../assets/storageBox.svg";
import Sidebar from "../../components/sidebar";
import AttachmentIcon from "../../assets/AttachmentIcon.png";
import DownloadIcon from "@mui/icons-material/Download";
import backgroundVideoLink from "../../assets/backgroundChat.svg";
import NotChatImage from "../../assets/buzon1.svg";
import MessagingBar from "../../components/messagingBar";
import AttentionInformation from "../../components/attentionInformation";
import AdditionalInformation from "../../components/additionalInformation";
import ChatItem from "../../components/chatItem";
import SkeletonAgent from "../../components/skeletonAgent";
import { environment } from "../../config";
import { CircularProgress } from "@mui/material";
import ContactForm from "../../components/contactForm";

import service from "../../service";
import { ContactFormNew } from "../../components/contactForm/contactForm";
import { CardChat, WrapperCardEmpy } from "../../components/cardChat/cardChat";
import { AgentContext } from "../../context";
import { ListDocument } from "../../components/listDocument/listDocument";

const DesktopView = ({
  container,
  handleSidebar,
  setConfigDialog,
  onLogout,
  handleDialogClose,
  setCurrentChat,
  socket,
  onSendMessage,
  onUploadImageMessage,
  connect,
  getCurrentFiles,
  getCurrentChat,
  handleAgentConnect,
  setIsPreviewImages,
  setSelectedImage,
  navigate,
  isLoading,
  cleanInput,
  height,
  setIsLoading,
  loading,
  width,
  createContact,
  getMoreMessages,
  loadingMoreMessages,
  contactsInfo,
  mergeContact,
  getContactsInfo,
  contactsMatch,
  state,
}) => {
  // const { state } = useContext(AgentContext);
  const [showContactForm, setShowContactForm] = useState(false);

  useEffect(() => {
    setShowContactForm(false);
    setTimeout(() => {
      setShowContactForm(true);
    }, 1);
  }, [state.currentChat]);

  return !loading ? (
    <>
      <Box style={{ display: "flex", overflow: "hidden" }}>
        <Navbar
          isMobile={false}
          page={"chat"}
          currentChat={state.currentChat}
          isLoading={loading}
          isOpenSideMenu={state.isOpen}
          handleDrawerToggle={handleSidebar}
          setOpenDialog={setConfigDialog}
          openDialog={state.configDialog}
        />
        <Box
          component="nav"
          sx={{
            width: { md: state.isOpen ? 240 : 80 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folder"
        >
          <Sidebar
            typeConnection={""}
            drawerwidth={state.isOpen ? 240 : 80}
            handleDrawerToggle={handleSidebar}
            container={container}
            isOpenSideMenu={state.isOpen}
            onHandlePage={(pathname) => navigate(pathname)}
            onLogout={onLogout}
            page={"chat"}
            user={state.user}
            openDialogClose={handleDialogClose}
            groups={state.groups}
            abilities={state.abilities}
            connect={connect}
            handleAgentConnect={handleAgentConnect}
            currentChat={state.currentChat}
            queue={state.queue}
            chatList={state.chatList}
            avatar={state?.user?.avatar}
            height={height}
            loading={loading}
          />
        </Box>

        <Box
          style={{
            width: `calc(100% - ${state.isOpen ? 240 : 80}px)`,
            display: "flex",
            alignItems: "center",
            paddingTop: 60,
            // overflow: "hidden",
          }}
        >
          <Box
            style={{
              height: "calc(100vh - 60px)",
              backgroundColor: "#f5f5f5",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingTop: "4px",
              width: "100%",
              maxWidth: "400px",
            }}
          >
            <Box
              style={{
                marginLeft: "13px",
                fontWeight: "500",
                marginBottom: "8px",
                color: "#000000",
              }}
            >
              {`Personas en cola: ${
                state.queue.length > 0 ? state.queue.length : 0
              }`}
            </Box>
            <Box
              style={{
                height: "168px",
                backgroundColor: "#FFFFFF",
                marginBottom: "8px",
                padding: "8px",
                display: "-webkit-box",
                overflowX: "scroll",
                maxWidth: "100%",
                // display: "flex",
                paddingTop: "24px",
                filter: !connect && "blur(1px)",
              }}
            >
              {state.queue.length > 0 ? (
                state.queue.map((item, index) => (
                  <LinePeopleItem
                    key={index}
                    item={item}
                    groups={state.groups}
                  />
                ))
              ) : (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    // alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      width: 58,
                      height: 58,
                      borderRadius: "100%",
                      backgroundColor: "#DFDFDF",
                      marginRight: 30,
                    }}
                  ></Box>
                  <Box
                    style={{
                      width: 58,
                      height: 58,
                      borderRadius: "100%",
                      backgroundColor: "#DFDFDF",
                      marginRight: 30,
                    }}
                  ></Box>
                  <Box
                    style={{
                      width: 58,
                      height: 58,
                      borderRadius: "100%",
                      backgroundColor: "#DFDFDF",
                    }}
                  ></Box>
                </Box>
              )}
            </Box>
            <Box
              style={{
                marginLeft: "12px",
                marginRight: "12px",
                marginBottom: "32px",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                style={{
                  height: 36.5,
                  borderRadius: "25.5px",
                  backgroundColor:
                    state.queue?.filter(
                      (chat) =>
                        state.user?.groups?.includes(chat?.group) &&
                        state.user?.abilities?.includes(chat?.ability._id)
                    ).length === 0 ||
                    !connect ||
                    state.chatList.length === 5 ||
                    loading ||
                    isLoading
                      ? "#C5C5C5"
                      : "#EA345F",
                  color: "white",
                }}
                disabled={
                  state.queue.filter(
                    (chat) =>
                      state.user?.groups?.includes(chat.group) &&
                      state.user?.abilities?.includes(chat.ability._id)
                  ).length === 0 ||
                  state.chatList.length === 5 ||
                  loading ||
                  !connect ||
                  isLoading
                }
                onClick={async () => {
                  setIsLoading(true);
                  socket?.emit("next");
                }}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#FFFFFF", width: 24, height: 24 }}
                  />
                ) : (
                  "TOMAR ATENCIÓN"
                )}
              </Button>
            </Box>
            <Box
              style={{
                marginTop: state.queue.length === 0 && 8,
                marginLeft: "13px",
                fontWeight: "500",
                color: "#000000",
                marginBottom: "8px",
              }}
            >
              Atenciones en curso
            </Box>
            <Box
              style={{
                maxHeight: "calc(100vh - 380px)",
                overflowY: "scroll",
                overflowX: "hidden",
                height: state.chatList.length === 0 && "calc(100vh - 380px)",
                backgroundColor: state.chatList.length === 0 && "white",
                display: state.chatList.length === 0 && "flex",
                alignItems: state.chatList.length === 0 && "center",
                justifyContent: state.chatList.length === 0 && "center",
              }}
            >
              {/* {state.chatList.length > 0 ? (
                state.chatList.map((item, index) => (
                  <LineChatItem
                    state={state}
                    key={index}
                    id={item._id}
                    count={item.count ? item.count : 0}
                    history={item.history}
                    messages={item.history}
                    item={item}
                    canal={item?.channel}
                    name={
                      item && item.contactV3
                        ? `${item?.contactV3?.firstName ?? ""} ${
                            item?.contactV3?.lastName ?? ""
                          }`.trim()
                        : "No especificado"
                    }
                    ability={item.ability.name}
                    onSelected={setCurrentChat}
                    getCurrentChat={getCurrentChat}
                    isLast={state.chatList.length - 1 === index}
                    connect={connect}
                  />
                ))
              ) : (
                <Box style={{ padding: 8 }}>
                  <Box style={{ textAlign: "center" }}>
                    <img
                      src={storageBox}
                      alt=""
                      style={{ width: 100, heigth: 48 }}
                    />
                  </Box>
                  <Box style={{ color: "#C4C4C4", textAlign: "center" }}>
                    No tiene atenciones abiertas
                  </Box>
                </Box>
              )} */}
              {state.chatList.length > 0 ? (
                state.chatList.map((item, index) => (
                  <CardChat
                    key={index}
                    item={item}
                    getCurrentChat={getCurrentChat}
                  />
                ))
              ) : (
                <WrapperCardEmpy />
              )}
            </Box>
          </Box>
          <Box
            style={{
              height: "calc(100vh - 60px)",
              backgroundColor: "#FFFFFF",
              // paddingLeft: "8px",
              // paddingRight: "8px",
              paddingTop: state?.currentChat?.isNext ? 0 : 4,
              // width: state.currentChat ? "50%" : "75%",
              flexGrow: 1,
              backgroundImage: `url(${backgroundVideoLink})`,
              position: "relative",
              display: isLoading ? "flex" : !state.currentChat && "flex",
              justifyContent: isLoading
                ? "center"
                : !state.currentChat && "center",
              alignItems: isLoading ? "center" : !state.currentChat && "center",
            }}
          >
            <Box
              id="contianed-chat-vl"
              style={{
                overflowX: "auto",
                maxHeight: `calc(90vh - ${width < 1550 ? "68px" : "50px"})`,
                paddingBottom: width < 1550 ? 30 : 15,
                scrollBehavior: "auto",
              }}
            >
              {!isLoading &&
                state &&
                state.currentChat &&
                state.currentChat.isNext && (
                  <Box
                    style={{
                      background: "red",
                      padding: 8,
                      color: "rgb(0, 0, 0)",
                      background: "rgb(243, 246, 249)",
                      cursor: loadingMoreMessages ? "default" : "pointer",
                    }}
                    onClick={() => !loadingMoreMessages && getMoreMessages()}
                  >
                    {loadingMoreMessages ? (
                      <CircularProgress style={{ width: 16, height: 16 }} />
                    ) : (
                      "Cargar mensajes anteriores"
                    )}
                  </Box>
                )}

              <Box style={{ padding: 16 }}>
                {isLoading ? (
                  <>
                    <Box style={{ textAlign: "center" }}>
                      <Box>
                        <img
                          style={{ width: "32px", marginTop: "48px" }}
                          src={loading}
                          alt=""
                        />
                      </Box>
                    </Box>
                  </>
                ) : state.currentChat ? (
                  state.messages.map((item, index) => {
                    return (
                      <ChatItem
                        currentChat={state.currentChat}
                        isUser={!item.isAgent}
                        key={index}
                        message={item.content?.text}
                        id={item.content?.id}
                        name={item.content?.name}
                        agent={state.user}
                        date={item.date}
                        agents={state.agents}
                        client={state.currentChat.menbers[0]}
                        item={item}
                        setIsImgView={setIsPreviewImages}
                        setSelectedImage={setSelectedImage}
                      />
                    );
                  })
                ) : (
                  <>
                    <Box style={{ textAlign: "center" }}>
                      <Box>
                        <img
                          style={{ width: "250px", marginTop: "48px" }}
                          src={NotChatImage}
                          alt=""
                        />
                      </Box>
                      <Box
                        style={{
                          color: "#C4C4C4",
                          fontWeight: "bold",
                          fontSize: "24px",
                        }}
                      >
                        No has tomado un chat
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <MessagingBar
              currentChat={state.currentChat}
              onSendMessage={onSendMessage}
              onInitCall={console.log}
              onUploadImageMessage={onUploadImageMessage}
              user={state.user}
              getCurrentFiles={getCurrentFiles}
              cleanInput={cleanInput}
              state={state}
            />
          </Box>
          {state.currentChat && (
            <Box
              style={{
                height: "calc(100vh - 60px)",
                backgroundColor: "#f5f5f5",
                paddingLeft: "8px",
                paddingRight: "8px",
                paddingTop: "4px",
                width: "100%",
                maxWidth: "400px",
                overflow: "scroll",
              }}
            >
              <AttentionInformation
                currentChat={state.currentChat}
                groups={state.groups}
              />
              {state &&
              state.user &&
              state.user.preferences &&
              // state.user.preferences.isShowContactForm &&
              state.currentChat ? (
                //&&  state.currentChat.contact
                <ContactFormNew
                  generalState={state}
                  getCurrentChat={getCurrentChat}
                  getMoreMessages={getMoreMessages}
                />
              ) : (
                <></>
              )}

              <ListDocument state={state} />
            </Box>
          )}
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress style={{ color: "#EA345F" }} />
      </Backdrop>
    </>
  ) : (
    <>
      <Box sx={{ display: "flex" }}>
        <Navbar
          isMobile={false}
          page={"chat"}
          currentChat={state.currentChat}
          isLoading={loading}
          isOpenSideMenu={state.isOpen}
          handleDrawerToggle={handleSidebar}
          setOpenDialog={setConfigDialog}
          openDialog={state.configDialog}
        />
        <Box
          component="nav"
          sx={{
            width: { md: state.isOpen ? 240 : 80 },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Sidebar
            typeConnection={""}
            handleAgentConnect={console.log}
            drawerwidth={state.isOpen ? 240 : 80}
            handleDrawerToggle={handleSidebar}
            container={container}
            isOpenSideMenu={state.isOpen}
            onHandlePage={console.log}
            onLogout={onLogout}
            page={"chat"}
            user={state.user}
            openDialogClose={handleDialogClose}
            groups={state.groups}
            abilities={state.abilities}
            connect={false}
            isLoading={loading}
            avatar={state?.user?.avatar}
            loading={loading}
          />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: {
              sm: `calc(100% - ${state.isOpen ? 240 : 80}px)`,
            },
          }}
          style={{ overflow: "hidden" }}
        >
          <Box style={{ paddingTop: 60 }}>
            {" "}
            <SkeletonAgent toolbarHeight={60} mobileBreakpoint={true} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DesktopView;
