import { useEffect, useState } from "react";
import { environment } from "../config";

import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Skeleton from "@mui/material/Skeleton";
import CloseIcon from "@mui/icons-material/Close";
import DateObject from "react-date-object";

import { getCanalIcon } from "../utils";
import backgroundVideoLink from "../assets/backgroundChat.svg";
import service from "../service";

function ImgView({
  selectedImage,
  setSelectedImage,
  currentChat,
  filteredMessages,
  isLoadingImgView,
  setIsImgView,
}) {
  const { firstName = "", lastName = "" } = currentChat?.contactV3 ?? {};

  let clientName = firstName
    ? `${firstName} ${lastName ?? ""}`.trim()
    : "Sin especificar";

  return (
    <div>
      <MuiAppBar position="fixed">
        <Toolbar style={{ backgroundColor: "#1B0845", height: 85 }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {isLoadingImgView ? (
              <Skeleton
                variant="circular"
                width={48}
                height={48}
                style={{ backgroundColor: "#0B1022" }}
              />
            ) : (
              <img
                src={getCanalIcon(currentChat?.channel)}
                style={{ width: "48px", marginRight: "8px" }}
                alt="canal"
              />
            )}
          </Box>
          <Box>
            <Box style={{ fontWeight: "bold" }}>
              {isLoadingImgView ? (
                <Skeleton
                  variant="text"
                  width={200}
                  height={35}
                  style={{ backgroundColor: "#0B1022" }}
                />
              ) : (
                <>{clientName}</>
              )}
            </Box>
            <Box>
              {isLoadingImgView ? (
                <Skeleton
                  variant="text"
                  width={200}
                  height={35}
                  style={{ backgroundColor: "#0B1022" }}
                />
              ) : (
                new DateObject(new Date(selectedImage?.date)).format(
                  "DD/MM/YYYY hh:mm:ss a"
                )
              )}

              {/* dddd DD MMMM YYYY */}
            </Box>
          </Box>
          <Box style={{ marginLeft: "auto" }}>
            {isLoadingImgView ? (
              <Skeleton
                variant="circular"
                width={48}
                height={48}
                style={{ backgroundColor: "#0B1022" }}
              />
            ) : (
              <IconButton
                onClick={() => setIsImgView(false)}
                style={{ backgroundColor: "#C5C5C5", color: "#FFFFFF" }}
              >
                <CloseIcon />
              </IconButton>
            )}
            {/* <IconButton
              style={{ color: "#FFFFFF", marginRight: 12 }}
              size="large"
            >
              <DownloadIcon />
            </IconButton> */}
          </Box>
        </Toolbar>
      </MuiAppBar>
      <Box
        style={{
          marginTop: filteredMessages.length > 0 && 85,
          height:
            filteredMessages.length > 0
              ? `calc(100vh - ${240}px)`
              : `calc(100vh)`,
          backgroundImage: `url(${backgroundVideoLink})`,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box style={{ marginRight: "auto", marginLeft: 24 }}>
          {isLoadingImgView ? (
            <Skeleton variant="circular" width={48} height={48} />
          ) : (
            filteredMessages.length > 0 && (
              <IconButton
                id="leftButton"
                disabled={
                  (selectedImage?.index === 1 && true) ||
                  filteredMessages.length < 1
                }
                style={{ marginRight: 8, backgroundColor: "#C4C4C4" }}
                onClick={() => {
                  setSelectedImage(
                    filteredMessages.find(
                      (item) => item.index === selectedImage.index - 1
                    )
                  );
                }}
              >
                <KeyboardArrowLeftIcon style={{ color: "#FFFFFF" }} />
              </IconButton>
            )
          )}
        </Box>
        <Box
          sx={{
            width: {
              xs: `300px`,
              md: "450px",
            },
          }}
        >
          {isLoadingImgView ? (
            <Skeleton
              variant="rectangular"
              style={{ width: "100%", height: "500px" }}
            />
          ) : filteredMessages.length < 1 ? (
            <div style={{ textAlign: "center" }}>No hay documentos.</div>
          ) : (
            <a
              href={
                selectedImage?.content.url
                  ? selectedImage.content.url
                  : `${environment.storage}/c/${selectedImage?.content.id}`
              }
              target="_blank"
            >
              <img
                src={
                  selectedImage?.content.url
                    ? selectedImage.content.url
                    : `${environment.storage}/c/${selectedImage?.content.id}?w=162&auto=format`
                }
                srcSet={
                  selectedImage?.content.url
                    ? selectedImage.content.url
                    : `${environment.storage}/c/${selectedImage?.content.id}?w=162&auto=format&dpr=2 2x`
                }
                alt={selectedImage?.content.id}
                loading="lazy"
                style={{
                  borderRadius: 10,
                  width: "100%",
                  maxHeight: "calc(100vh - 256px)",
                  objectFit: "contain",
                }}
              />
            </a>
          )}
        </Box>
        <Box style={{ marginLeft: "auto", marginRight: 24 }}>
          <Box style={{ marginRight: "auto" }}>
            {isLoadingImgView ? (
              <Skeleton variant="circular" width={48} height={48} />
            ) : (
              filteredMessages.length > 0 && (
                <IconButton
                  id="rigthButton"
                  disabled={
                    selectedImage?.index === filteredMessages.length ||
                    filteredMessages.length < 1
                  }
                  style={{ backgroundColor: "#C4C4C4", marginLeft: 8 }}
                  onClick={() => {
                    setSelectedImage(
                      filteredMessages.find(
                        (item) => item.index === selectedImage.index + 1
                      )
                    );
                  }}
                >
                  <KeyboardArrowRightIcon style={{ color: "#FFFFFF" }} />
                </IconButton>
              )
            )}
          </Box>
        </Box>
      </Box>
      {filteredMessages.length > 0 && (
        <Box
          style={{
            bottom: 0,
            position: "fixed",
            height: 150,
            display: "-webkit-box",
            alignItems: "center",
            border: "1px solid rgba(0, 0, 0, 0.16)",
            boxSizing: "border-box",
            width: "-webkit-fill-available",
            marginLeft: -7,
            padding: 8,
            overflowX: "auto",
            justifyContent: "center",
            webkitBoxPack: "center",
          }}
        >
          {isLoadingImgView
            ? [1, 2, 3].map((item) => (
                <Skeleton
                  key={item}
                  variant="rectangular"
                  width={120}
                  height={120}
                  style={{ marginRight: 8 }}
                />
              ))
            : filteredMessages.map((item, index) => (
                <Box
                  style={{ display: "flex", marginRight: 8 }}
                  sx={{
                    width: {
                      xs: `120px`,
                      md: "120px",
                    },
                  }}
                  key={index}
                >
                  <img
                    src={
                      item?.content?.url
                        ? item?.content.url
                        : `${environment.storage}/c/${item.content.id}?w=120&auto=format`
                    }
                    srcSet={
                      item?.content?.url
                        ? item?.content.url
                        : `${environment.storage}/c/${item.content.id}?w=120&auto=format&dpr=2 20x`
                    }
                    alt={index}
                    onClick={() => setSelectedImage(item)}
                    loading="lazy"
                    className={"img-vl-gallery"}
                    style={{
                      borderRadius: 10,
                      border:
                        selectedImage?.index === item.index &&
                        "solid 2px rgb(234 52 95 / 100%)",
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              ))}
        </Box>
      )}
    </div>
  );
}

export default ImgView;
